import { colorTokens, type Skin } from '@orus.eu/pharaoh'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import PreviiHorizontalLogo from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.svg'

const openSansFamily = 'open-sans'
const openSansUrl = 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'
export const previiSkin: Skin = {
  avatarUrl,
  quoteCardHelperBackgroundColor: colorTokens['color-bg-decorative-1'],
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrl,
  },
  horizontalLogo: {
    aspectRatio: 71 / 24,
    ImageComponent: PreviiHorizontalLogo,
  },
  showByOrusInHeader: 'by',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colorTokens['color-fg-decorative'],
    backgroundColor: colorTokens['color-bg-decorative-1'],
  },
  subscriptionCalloutColors: {
    backgroundColor: '#1F0F69',
    accentColor: '#E7E4F4',
    textColor: '#E7E4F4',
  },
  stripe: {
    fontFamily: openSansFamily,
    fontFamilyUrl: openSansUrl,
  },

  additionalStylesheets: [],

  cssVariables: {
    '--skin-blue-100': 'var(--Blue-100)',
    '--skin-blue-200': 'var(--Blue-200)',
    '--skin-blue-300': 'var(--Blue-300)',
    '--skin-blue-400': 'var(--Blue-400)',
    '--skin-blue-500': 'var(--Previi_Blue)',
    '--skin-blue-600': 'var(--Previi_Blue)',
    '--skin-blue-700': 'var(--Previi_Blue-dark)',
    '--skin-blue-800': 'var(--Previi_Blue-dark)',
    '--skin-blue-900': 'var(--Previi_Blue-dark)',
    '--skin-gray-100': 'var(--Previi_Gray-light)',
    '--skin-gray-200': 'var(--Previi_Gray-light)',
    '--skin-gray-300': 'var(--Previi_Gray-light)',
    '--skin-gray-400': 'var(--Previi_Gray)',
    '--skin-gray-500': 'var(--Previi_Gray)',
    '--skin-gray-600': 'var(--Previi_Gray)',
    '--skin-gray-700': 'var(--Previi_Gray)',
    '--skin-gray-800': 'var(--Previi_Gray)',
    '--skin-gray-900': 'var(--Gray-900)',
    '--skin-green-100': 'var(--Green-100)',
    '--skin-green-200': 'var(--Green-200)',
    '--skin-green-300': 'var(--Green-300)',
    '--skin-green-400': 'var(--Green-400)',
    '--skin-green-500': 'var(--Green-500)',
    '--skin-green-600': 'var(--Green-600)',
    '--skin-green-700': 'var(--Green-700)',
    '--skin-green-800': 'var(--Green-800)',
    '--skin-green-900': 'var(--Green-900)',
    '--skin-yellow-100': 'var(--Previi_Yellow)',
    '--skin-yellow-200': 'var(--Previi_Yellow)',
    '--skin-yellow-300': 'var(--Previi_Yellow)',
    '--skin-yellow-400': 'var(--Previi_Yellow)',
    '--skin-yellow-500': 'var(--Previi_Yellow)',
    '--skin-yellow-600': 'var(--Previi_Yellow)',
    '--skin-yellow-700': 'var(--Previi_Yellow)',
    '--skin-yellow-800': 'var(--Previi_Yellow)',
    '--skin-yellow-900': 'var(--Previi_Yellow)',
    '--skin-orange-100': 'var(--Orange-100)',
    '--skin-orange-200': 'var(--Orange-200)',
    '--skin-orange-300': 'var(--Orange-300)',
    '--skin-orange-400': 'var(--Orange-400)',
    '--skin-orange-500': 'var(--Orange-500)',
    '--skin-orange-600': 'var(--Orange-600)',
    '--skin-orange-700': 'var(--Orange-700)',
    '--skin-orange-800': 'var(--Orange-800)',
    '--skin-orange-900': 'var(--Orange-900)',
    '--skin-red-100': 'var(--Red-100)',
    '--skin-red-200': 'var(--Red-200)',
    '--skin-red-300': 'var(--Red-300)',
    '--skin-red-400': 'var(--Red-400)',
    '--skin-red-500': 'var(--Red-500)',
    '--skin-red-600': 'var(--Red-600)',
    '--skin-red-700': 'var(--Red-700)',
    '--skin-red-800': 'var(--Red-800)',
    '--skin-red-900': 'var(--Red-900)',
    '--skin-white': 'var(--White)',
    '--skin-black': 'var(--Black)',
    '--skin-linen': colorTokens['color-background'],
    '--skin-sky-dark': 'var(--Previi_Blue-dark)',
    '--skin-sky-light': 'var(--Previi_Blue-dark)',
    '--skin-sky-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Previi_Blue-dark) 0%, var(--Previi_Blue-dark) 100%)',
    '--skin-periwinkle-dark': 'var(--Previi_Blue-dark)',
    '--skin-periwinkle-light': 'var(--Previi_Blue-dark)',
    '--skin-periwinkle-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Previi_Blue-dark) 0%, var(--Previi_Blue-dark) 100%)',
    '--skin-mindaro-dark': 'var(--Previi_Blue-dark)',
    '--skin-mindaro-light': 'var(--Previi_Blue-dark)',
    '--skin-mindaro-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Previi_Blue-dark) 0%, var(--Previi_Blue-dark) 100%)',
    '--skin-jasmine-dark': 'var(--Previi_Blue-dark)',
    '--skin-jasmine-light': 'var(--Previi_Blue-dark)',
    '--skin-jasmine-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Previi_Blue-dark) 0%, var(--Previi_Blue-dark) 100%)',
    '--skin-peach-dark': 'var(--Previi_Blue-dark)',
    '--skin-peach-light': 'var(--Previi_Blue-dark)',
    '--skin-peach-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Previi_Blue-dark) 0%, var(--Previi_Blue-dark) 100%)',
  },
}
